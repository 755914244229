.image-section {
    padding: 30vh 0;
  }
  
  .image-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: '90%';
    margin: 0 auto;
  }
  
  .wave-emoji {
    flex: 0 0 33%;
    max-width: 20%;
    height: auto;
  }
  
  .email-image {
    flex: 0 0 75%;
    max-width: 75%;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .image-container {
      flex-direction: column;
      align-items: center;
    }
  
    .email-image {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 20px;
      padding: 0 5vw;
    }
    .wave-emoji {
      flex: 0 0 100%;
      max-width: 50%;
      max-height: 50%;
      margin-bottom: 20px;
    }
  }