.team-title {
  font-size: 5rem;
}

.team-section {
  padding: 50px;
}

.team-members {
  display: flex;
  flex-direction: column;
  gap: 41px;
  margin-top: 10vh;
  align-items: center;
}

.team-member {
  opacity: .5;
  transition: opacity 0.3s ease;
  width: 100%;
}

.team-member.active {
  opacity: 1;
}

.member-profile {
  display: flex;
  align-items: center;
  gap: 20px;
}

.member-profile.left {
  justify-content: flex-start;
}

.member-profile.right {
  justify-content: flex-end;
}

.photo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-photo {
  width: 30vh;
  height: 30vh;
  border-radius: 50%;
  object-fit: cover;
}

.member-details {
  flex: 1;
  max-width: 60%;
}

.member-details p {
  font-family: InstrumentSans;
  margin: 0;
  padding: 0 10vw;
  font-weight: bold;
  transition: opacity 1s ease;
}

.left .photo-container {
  margin-right: 5vw;
}

.right .photo-container {
  margin-left: 5vw;
}

@media (max-width: 768px) {
  .team-title {
    font-size: 3rem;
    text-align: center;
  }

  .team-section {
    padding: 30px;
  }

  .member-profile {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .member-details {
    max-width: 100%;
  }

  .left .photo-container,
  .right .photo-container {
    margin: 0;
  }
}