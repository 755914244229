.footer-container {
    background-color: black;
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2rem;
  }
  
  .footer-section {
    flex: 1;
    margin-right: 4rem;
    margin-bottom: 2rem;
    max-width: 30%;
  }
  
  .footer-section:last-child {
    margin-right: 0;
  }
  
  .connect-section {
    margin-left: auto;
    text-align: right;
  }
  
  .address {
    font-style: normal;
    margin-bottom: 0.5rem;
  }
  
  .footer-links {
    list-style: none;
    padding: 0;
  }
  
  .footer-links li {
    margin-bottom: 0.5rem;
  }
  
  .footer-links a {
    color: white;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  .social-links {
    display: flex;
    justify-content: flex-end;
  }
  
  .social-link {
    color: white;
    margin-left: 1.5rem;
    font-size: 1.5rem;
  }
  
  @media (max-width: 1024px) {
    .footer-section {
      max-width: 45%;
    }
  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .footer-section {
      margin-right: 0;
      margin-bottom: 2rem;
      max-width: 100%;
    }
  
    .connect-section {
      margin-left: 0;
      text-align: left;
    }
  
    .social-links {
      justify-content: flex-start;
    }
  }