.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15vh;
    height: 75vh;
    opacity: 0;
  }
  
  .image-container {
    width: 66%;
    height: 100%;
  }
  
  .text-container {
    width: 33%;
    padding: 0 5vw;
  }

  .bio-header{
    font-size: 4rem;
    font-weight: bold;
    z-index: 20;
  }

  .padded {
    padding: 0vh 0vw;
  }

  .padded-list {
    padding: 0vh 0vw;
  }
       
  @media (max-width: 1440px) {
    .bio-header {
        font-size: 2rem;
    }
  }
  
  @media (max-width: 768px) {
    .bio-header {
        font-size: 3rem;
    }
    .container {
      flex-direction: column;
      height: auto;
      margin-top: 10vh;
    }
  
    .image-container {
      width: 100%;
      height: 50vh;
    }
  
    .text-container {
      width: 100%;
      padding: 5vh 0vw;
    }

    .padded {
        padding: 0vh 5vw;
    }

    .padded-list {
        padding: 0vh 15vw;
    }
  }