.header-container {
    font-family: 'Alata';
  }
  
  .header {
    position: fixed;
    width: 100%;
    top: 0;
    background-color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 3px solid black;
    z-index: 50;
    box-sizing: border-box;
  }
  
  .logo {
    margin-left: 50px;
    height: 30px;
  }
  
  .nav {
    padding-right: 0px;
  }
  
  .nav-list {
    display: flex;
    list-style: none;
  }
  
  .nav-item {
    margin-right: 20px;
  }
  
  .nav-link {
    text-decoration: none;
    color: inherit;
  }
  
  @media (max-width: 768px) {
    .header {
      padding: 10px;
    }
  
    .logo {
      margin-left: 0;
    }
  }